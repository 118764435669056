import { useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"
import { useTimeout } from "usehooks-ts"

import { gray60 } from "src/ui/colors"
import CopyIcon from "src/ui/icons/copy.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function CopyBox({
  text,
  title,
}: {
  text: string
  title?: React.ReactNode
}) {
  const [openTooltip, setOpenTooltip] = useState<number>()

  async function handleCopy() {
    await navigator.clipboard.writeText(text)
    setOpenTooltip(2000)
  }

  useTimeout(() => {
    setOpenTooltip(undefined)
  }, openTooltip ?? null)

  return (
    <BoxFrame>
      {title && <TitleBox>{title}</TitleBox>}
      <TextBox>{text}</TextBox>
      <MTooltip title={"Copied!"} open={!!openTooltip}>
        <IconButton onClick={handleCopy} size="small">
          <CopyIcon height={24} />
        </IconButton>
      </MTooltip>
    </BoxFrame>
  )
}

const TitleBox = styled.div`
  line-height: 1;
`

const TextBox = styled.code`
  flex: 1;
  line-height: 1;
  font-size: 0.875rem;
`

const BoxFrame = styled.div`
  background: ${gray60};
  padding: ${spacing.S} ${spacing.S};
  border-radius: 4px;
  display: flex;
  gap: 1ch;
  align-items: center;
`
